<template>
  <div>
    <vs-popup
      class="widget_settings_popup"
      :title="lang.botMaker.addBot.facebookConfig.title[languageSelected]"
      width="900px"
      :active.sync="active"
    >
      <FacebookSettings
        v-if="active"
        ref="facebookSettings"
        @closePopup="active = false"
        :key="keyBotWidget"
        :channel="channel"
        :apiKey="apikey"
        @changeFacebookApiKey="updateApiKey"
      />
    </vs-popup>
    <KonaPopup
      ref="konaPopup"
      type="warning"
      :title="lang.botMaker.addBot.botChannels.confirm.title[languageSelected]"
    >
      <span class="channel-integration-warning">
        {{ lang.botMaker.addBot.widget.openError[languageSelected] }}
        <a :href="`mailto:${MAILS.support}`" target="_blank">
          {{ MAILS.support }}
        </a>
      </span>
    </KonaPopup>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FacebookSettings from './FacebookSettings.vue'
import { MAILS } from '@/constants/constants'

export default {
  props: {
    channel: Object,
    apikey: String
  },
  components: {
    FacebookSettings,
    KonaPopup: () => import('../../../../../components/KonaPopup.vue')
  },
  beforeDestroy() {
    this.active = false
  },
  data() {
    return {
      MAILS,
      keyBotWidget: new Date().getTime(),
      active: false
    }
  },
  watch: {
    active() {
      this.keyBotWidget = new Date().getTime()
    }
  },
  methods: {
    open() {
      this.active = true
    },
    close() {
      this.active = false
    },
    updateApiKey(apikey) {
      this.$emit('changeFacebookApiKey', apikey)
      this.close()
    }
  },
  computed: {
    ...mapGetters('bots', ['bot', 'validations', 'sectionReady']),
    ...mapState(['lang', 'languageSelected', 'companyAuthKey', 'companyId'])
  }
}
</script>

<style lang="scss">
.facebook_settings_popup.con-vs-popup {
  .vs-popup {
    width: 900px;
  }
}
</style>
