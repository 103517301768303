<template>
  <div :key="keyBotWidget" class="facebook_settings img-drop-shadow">
    <div ref="facebook_settings_content" class="facebook_settings_content">
      <div class="flex justify-between items-center">
        <div class="mr-4">
          <label for="webhook">Webhook</label>
          <br />
          <code>{{ webhook }}</code>
        </div>
        <vs-button
          class="copy-button"
          size="small"
          icon="icon-clipboard"
          icon-pack="feather"
          color="primary"
          type="border"
          v-clipboard="() => webhook"
          v-clipboard:success="clipboardSuccessHandler"
          v-clipboard:error="clipboardErrorHandler"
        >
          {{ lang.general.copyToClipboard.label[languageSelected] }}
        </vs-button>
      </div>

      <div class="flex justify-between items-center mt-4">
        <div class="mr-4">
          <label for="token">
            {{ lang.botMaker.editBot.verifyToken[languageSelected] }}
          </label>
          <br />
          <code>{{ channel.meta.verifyToken }}</code>
        </div>
        <vs-button
          class="copy-button"
          size="small"
          icon="icon-clipboard"
          icon-pack="feather"
          color="primary"
          type="border"
          v-clipboard="() => channel.meta.verifyToken"
          v-clipboard:success="clipboardSuccessHandler"
          v-clipboard:error="clipboardErrorHandler"
        >
          {{ lang.general.copyToClipboard.label[languageSelected] }}
        </vs-button>
      </div>

      <div class="mt-4 mb-2">
        <label for="apiKey">
          {{ lang.botMaker.editBot.accessToken[languageSelected] }}
        </label>
        <div class="facebook_settings_apikey">
          <vs-input v-model="apikey" class="fill inputapikey" id="apiKey" />
          <vs-button
            class="vs-buttonx ml-2"
            color="primary"
            type="filled"
            @click="saveApiKey()"
          >
            <span>
              {{ lang.botMaker.editBot.saveApiKey[languageSelected] }}
            </span>
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BotMakerService from '../../../../../services/botMaker.service'
import state from '../../../../../store/state'
import { mapGetters, mapState, mapMutations } from 'vuex'
export default {
  name: 'FacebookSettings',
  props: {
    isEditing: Boolean,
    channel: Object,
    apiKey: String
  },
  data() {
    return {
      webhook: '',
      apikey: '',
      state,
      keyBotWidget: new Date().getTime()
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('bots', ['bot', 'validations', 'sectionReady']),
    ...mapMutations('integrations', ['UPDATE_INTEGRATION'])
  },
  methods: {
    async saveApiKey() {
      if (!this.apikey) {
        this.$vs.notify({
          text: this.lang.botMaker.editBot.notApiKey[this.languageSelected],
          color: 'danger'
        })
        return
      }
      this.$vs.loading()
      try {
        await BotMakerService.setFacebookApiKey(this.channel._id, this.apikey)
        this.$emit('changeFacebookApiKey', this.apikey)
        // eslint-disable-next-line vue/no-mutating-props
        // this.channel.config = {}
        // eslint-disable-next-line vue/no-mutating-props
        // this.channel.config.apiKey = this.apikey
        this.keyBotWidget = new Date().getTime()
        this.$vs.notify({
          text: this.lang.botMaker.editBot.doneApiKey[this.languageSelected],
          color: 'success'
        })
      } catch (ex) {
        this.$vs.notify({
          text: this.lang.botMaker.editBot.notDoneApiKey[this.languageSelected],
          color: 'danger'
        })
      }
      this.$vs.loading.close()
    },
    clipboardSuccessHandler() {
      this.$vs.notify({
        text: this.lang.general.copyToClipboard.success[this.languageSelected],
        color: 'success'
      })
    },
    clipboardErrorHandler() {
      this.$vs.notify({
        text: this.lang.general.copyToClipboard.error[this.languageSelected],
        color: 'danger'
      })
    }
  },
  mounted() {
    this.apikey = this.apiKey
    const botId = this.$route.query.botId
    this.webhook =
      this.state.config.konecta +
      '/conversation/facebook/' +
      botId +
      `?integration=` +
      this.channel._id
  }
}
</script>

<style lang="scss">
.vs-tooltip {
  z-index: 55000 !important;
}
.facebook_settings {
  &_advance {
    box-shadow: 1px 1px 10px #00000045;
    max-width: 90%;
    margin: auto;
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    .enable_auto_btn {
      margin-left: 7px;
    }
    .restore_btn {
      margin-left: 20px;
    }
    label {
      padding-left: 15px;
    }
    h1 {
      padding-left: 30px;
      font-weight: bold;
      font-size: 20px;
    }
    .position_row {
      max-width: 500px;
    }
    h3 {
      padding-left: 15px;
      font-weight: 500;
      font-size: 16px;
    }
    .vs-inputx {
      width: 100px;
      height: 27px;
      text-align: center;
      margin: 5px 10px;
    }
    .vs-input-number {
      width: 100px;
    }
    .vs-col {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  &_padding_row {
    max-width: 400px;
    label {
      padding-left: 15px;
    }
    .vs-input-number {
      width: 100px;
    }
  }
  &_content {
    max-height: 75vh;
    overflow: auto;
  }
  &_footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    padding-bottom: 0;
    .vs-button {
      margin-right: 15px;
    }
  }

  .facebook_settings_content {
    code {
      word-break: break-all;
    }
    .facebook_settings_apikey {
      display: flex;
      flex-direction: row;
      align-items: center;

      .vs-textarea:focus {
        resize: none !important;
      }
      .vs-buttonx {
        width: 200px;
      }
    }
    .copy-button {
      max-width: 74px;
    }
  }
}
.img-drop-shadow img {
  filter: drop-shadow(3px 2px 2px);
}
</style>
